import { Caption } from '@mediahuis/chameleon-react';

import { DynamicIcon, type DynamicIconProps } from '@hubcms/ui-chameleon';

import { NavigationLink } from '../NavigationLink';

import styles from './menu-item.module.scss';

type MenuItemProps = {
  href: string;
  leftIconName?: DynamicIconProps['name'];
  rightIconName?: DynamicIconProps['name'];
  text: string;
  target?: string;
  trackingBlock: string;
};

export function MenuItem({ href, leftIconName, rightIconName, text, trackingBlock, target }: MenuItemProps) {
  return (
    <NavigationLink className={styles.menuItem} href={href} trackingName={text} trackingBlock={trackingBlock} target={target}>
      {!!rightIconName && <DynamicIcon name={rightIconName} />}
      <Caption size="lg" weight="strong" className={styles.text}>
        {text}
      </Caption>
      {!!leftIconName && <DynamicIcon name={leftIconName} />}
    </NavigationLink>
  );
}
