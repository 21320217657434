import cx from 'classnames';
import type { AnchorHTMLAttributes, DetailedHTMLProps, PropsWithChildren } from 'react';

import { useTrackingData } from '@hubcms/data-access-tracking';
import { useCiamUrl } from '@hubcms/utils-navigation';

import { createNavigationItemClickHandler } from './createNavigationClickHandler';
import styles from './navigation-link.module.scss';

export interface NavigationLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  trackingName?: string;
  trackingBlock?: string;
  target?: string;
}

export function NavigationLink({
  children,
  className,
  href,
  trackingName,
  trackingBlock,
  target = '_self',
  ...rest
}: PropsWithChildren<NavigationLinkProps>) {
  const trackingData = useTrackingData();
  const hrefFinal = useCiamUrl(trackingData, href, trackingBlock);

  const onClickHandler = createNavigationItemClickHandler({
    href: hrefFinal,
    trackingName,
    trackingBlock,
  });

  return (
    <a {...rest} className={cx(styles.navigationLink, className)} href={hrefFinal} onClick={onClickHandler} target={target}>
      {children}
    </a>
  );
}
