import { Icon } from '@mediahuis/chameleon-react';
import { ExternalLink } from '@mediahuis/chameleon-theme-wl/icons';
import type { ReactElement, ReactNode } from 'react';

import type { NavItem } from '@hubcms/domain-navigation';
import { ConditionalContainer } from '@hubcms/ui-container';
import { NavigationLink } from '@hubcms/ui-navigation-2';

import { NavigationItem } from '../NavigationItem';

import styles from './aside-menu.module.scss';

export type AsideMenuLinksSectionProps = {
  extraElement?: ReactElement;
  items: NavItem[];
  title?: string;
};

export function AsideMenuLinksSection({ extraElement, items, title }: AsideMenuLinksSectionProps) {
  if (items.length > 0 || extraElement) {
    return (
      <section className={styles.linksSection}>
        {title && <h6 className={styles.title}>{title}</h6>}

        {items.map(item => (
          <NavigationItem key={item.uniqueId} navItem={item}>
            {({ href, isExternal }) => {
              return (
                <ConditionalContainer condition={isExternal} renderContainer={renderContainer}>
                  <NavigationLink
                    className={styles.link}
                    href={href}
                    trackingName={item.text}
                    target={item.target}
                    trackingBlock="navigation-menu-services"
                  >
                    {item.text}
                  </NavigationLink>
                  {isExternal && <Icon as={ExternalLink} size="sm" />}
                </ConditionalContainer>
              );
            }}
          </NavigationItem>
        ))}

        {extraElement}
      </section>
    );
  }

  return null;
}

function renderContainer(children: ReactNode) {
  return <span className={styles.linkContainer}>{children}</span>;
}
